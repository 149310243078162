import { NotificationItem } from "../models/nofitications";
import moment from "moment";

export const formatDate = (date: string) => {
  const newDate = moment(date);

  return newDate.format("DD/MM/YYYY hh:mm");
};

export const sortArray = (arr: NotificationItem[], orderBy: string) => {
  switch (orderBy) {
    case "desc":
    default:
      return arr
        .slice()
        .sort((a, b) =>
          toTimestamp(a.created_at) > toTimestamp(b.created_at)
            ? 1
            : toTimestamp(b.created_at) > toTimestamp(a.created_at)
            ? -1
            : 0
        );
    case "asc":
      return arr
        .slice()
        .sort((a, b) =>
          toTimestamp(a.created_at) < toTimestamp(b.created_at)
            ? 1
            : toTimestamp(b.created_at) < toTimestamp(a.created_at)
            ? -1
            : 0
        );
  }
};

export const toTimestamp = (date: string) => {
  const timestamp = Date.parse(date);

  return timestamp / 1000;
};

export const deleteDuplicates = (data: any[]) => {
  const result = data.reduce((acc, item) => {
    if (!acc.includes(item)) {
      acc.push(item);
    }
    return acc;
  }, []);

  return result;
};
