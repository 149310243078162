import {
  Box,
  Modal,
  CloseIcon,
  Typography,
  Grid,
  TextField,
  CalendarTodayOutlinedIcon,
} from "@enerbit/base";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { formatDate } from "../../helpers";
import { NotificationsType, notificationsTypesNames } from "../../models/nofitications";

interface Props {
  handleClose: () => void;
  open: boolean;
}

const DetailsModal = ({ handleClose, open }: Props) => {
  const selectedNotification = useSelector(
    (state: RootState) => state.notifications.selectedNotification
  );
  return (
    <div>
      {selectedNotification && (
        <Modal open={open} onClose={handleClose}>
          <Box className='modal-content'>
            <Box className='modal-close'>
              <div onClick={handleClose}>
                <CloseIcon color='primary' />
              </div>
            </Box>
            <Typography variant='h5' color='primary.main'>
              Detalle de la notificación
            </Typography>
            <Grid sx={{ marginTop: "1px" }} container rowSpacing={3}>
              <Grid item xs={12}>
                <Typography variant='body1' color='neutral.700'>
                  Asunto
                </Typography>
                <TextField
                  disabled={true}
                  InputProps={{ className: "form-input disable" }}
                  value={selectedNotification.subject}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant='body1' color='neutral.700'>
                  Tipo de notificación
                </Typography>
                <TextField
                  disabled={true}
                  InputProps={{ className: "form-input disable" }}
                  value={
                    selectedNotification.topic
                      ? notificationsTypesNames[
                          selectedNotification.topic.name as NotificationsType
                        ]
                      : notificationsTypesNames[NotificationsType.DEFAULT]
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant='body1' color='neutral.700'>
                  Fecha:
                </Typography>
                <TextField
                  disabled={true}
                  InputProps={{
                    className: "form-input disable",
                    endAdornment: <CalendarTodayOutlinedIcon />,
                  }}
                  value={formatDate(selectedNotification.created_at)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant='body1' color='neutral.700'>
                  Mensaje
                </Typography>
                <TextField
                  multiline
                  rows={5}
                  disabled={true}
                  InputProps={{ className: "form-text-area" }}
                  defaultValue={selectedNotification.body}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Box>
        </Modal>
      )}
    </div>
  );
};

export default DetailsModal;
