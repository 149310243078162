import { api } from "@enerbit/base";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { NotificationsModel } from "../../models/nofitications";
import { setTotalPages, updateNotificationsTypes } from "../slices/notificationsSlice";
import { RootState } from "../store";
import { deleteDuplicates } from "../../helpers";

export const getNotifications = createAsyncThunk(
  "notifications/getNotifications",
  async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const currentPage = state.notifications.currentPage;

    const response = await api.get<NotificationsModel>(
      `/operator-notification/notifications?page=${currentPage}&size=7`
    );

    thunkApi.dispatch(setTotalPages(response.data.pages));

    const types: string[] = [];

    response.data.items.map((item) => {
      if (item.topic === null) {
        types.push("default");
      } else if (item.topic) {
        types.push(item.topic.name);
      }
    });

    const tempTypes = deleteDuplicates(types);
    thunkApi.dispatch(updateNotificationsTypes(tempTypes));

    return response.data.items;
  }
);
