import { Grid, MenuItem, SearchIcon, Select, TextField, Typography } from "@enerbit/base";
import { NotificationsType, notificationsTypesNames } from "../../models/nofitications";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { handleSearch, setSearchTerm, updateFilter } from "../../store/slices/notificationsSlice";

const NotificationForm = () => {
  const dispatch = useDispatch<AppDispatch>();
  const selectedFilter = useSelector((state: RootState) => state.notifications.selectedFilter);
  const searchTerm = useSelector((state: RootState) => state.notifications.searchTerm);
  const notificationsTypes = useSelector(
    (state: RootState) => state.notifications.notificationsTypes
  );

  return (
    <div className='form-container'>
      <Grid container rowSpacing={5} columnSpacing={6}>
        <Grid item xs={6}>
          <Typography mb={1} variant='body1' color='neutral.700'>
            Buscar notificaciones
          </Typography>
          <TextField
            fullWidth
            value={searchTerm}
            onChange={(event) => {
              dispatch(handleSearch(event.target.value));
              dispatch(setSearchTerm(event.target.value));
            }}
            InputProps={{
              className: "form-input",
              startAdornment: <SearchIcon />,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography mb={1} variant='body1' color='neutral.700'>
            Tipo de notificación
          </Typography>
          <Select
            fullWidth
            onChange={(event) => {
              dispatch(updateFilter(event.target.value));
            }}
            value={selectedFilter}
            className='form-input'>
            <MenuItem value={"all"}>Todas</MenuItem>
            {notificationsTypes.map((type, index) => (
              <MenuItem key={index} value={type}>
                {notificationsTypesNames[type as NotificationsType]}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
    </div>
  );
};

export default NotificationForm;
