import { Box, CircularProgress, CustomAlert, Typography } from "@enerbit/base";
import NotificationForm from "./components/NotificationForm";
import NotificationsTable from "./components/NotificationsTable";
import { useDispatch, useSelector } from "react-redux";
import "../styles/styles.css";
import { AppDispatch, RootState } from "../store/store";
import { getNotifications } from "../store/thunks/notifications";
import { useEffect } from "react";
import { updateEmpty } from "../store/slices/notificationsSlice";

const Notifications = () => {
  const isLoading = useSelector((state: RootState) => state.notifications.loading);
  const empty = useSelector((state: RootState) => state.notifications.empty);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getNotifications());
  }, []);

  return (
    <div className='main-container'>
      <Typography fontSize={"28px"} lineHeight={"34px"} color='primary.main'>
        Notificaciones
      </Typography>
      <NotificationForm />
      {isLoading && (
        <Box className='loader-container'>
          <CircularProgress />
        </Box>
      )}
      {!isLoading && !empty && <NotificationsTable />}
      {empty && (
        <Box mt={2}>
          <CustomAlert
            text='No tienes notifcaciones pendientes por revisar.'
            severity='info'
            onClose={() => dispatch(updateEmpty(false))}
          />
        </Box>
      )}
    </div>
  );
};

export default Notifications;
