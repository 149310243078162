import { createSlice } from "@reduxjs/toolkit";
import { NotificationsState, NotificationsType } from "../../models/nofitications";
import { getNotifications } from "../thunks/notifications";

const initialState: NotificationsState = {
  notifications: [],
  filteredNotifications: [],
  selectedNotification: undefined,
  loading: false,
  empty: true,
  error: false,
  totalPages: 0,
  currentPage: 0,
  selectedFilter: "all",
  searchTerm: "",
  notificationsTypes: [],
};

export const notificationsSlice = createSlice({
  name: "notificationsState",
  initialState,
  reducers: {
    updateNotifications(state, { payload }) {
      state.filteredNotifications = payload;
    },
    updateSelectedNotification(state, { payload }) {
      state.selectedNotification = payload;
    },
    setTotalPages(state, { payload }) {
      state.totalPages = payload;
    },
    setCurrentPage(state, { payload }) {
      state.currentPage = payload;
    },
    setSearchTerm(state, { payload }) {
      state.searchTerm = payload;
    },
    updateFilter(state, { payload }) {
      state.selectedFilter = payload;
      if (state.selectedFilter === "all") {
        state.filteredNotifications = state.notifications;
      } else {
        state.filteredNotifications = state.notifications.filter((item) => {
          if (state.selectedFilter === NotificationsType.DEFAULT) {
            return item.topic === null;
          }

          return item.topic?.name === state.selectedFilter;
        });
      }
    },
    handleSearch(state, { payload }) {
      if (payload === "") {
        if (state.selectedFilter === "all") {
          state.filteredNotifications = state.notifications;
        } else {
          state.filteredNotifications = state.notifications.filter((item) => {
            if (state.selectedFilter === NotificationsType.DEFAULT) {
              return item.topic === null;
            }
            return item.topic?.name === state.selectedFilter;
          });
        }
      } else {
        state.filteredNotifications = state.filteredNotifications.filter((item) => {
          return item.subject.toLocaleLowerCase().includes(payload);
        });
      }
    },
    updateEmpty(state, { payload }) {
      state.empty = payload;
    },
    updateNotificationsTypes(state, { payload }) {
      state.notificationsTypes = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNotifications.pending, (state) => {
      state.loading = true;
      state.error = false;
      state.empty = false;
    }),
      builder.addCase(getNotifications.fulfilled, (state, { payload }) => {
        state.notifications = payload;
        state.filteredNotifications = payload;
        if (payload.length === 0) {
          state.empty = true;
        }
        state.loading = false;
      }),
      builder.addCase(getNotifications.rejected, (state) => {
        state.loading = false;
        state.error = true;
        state.empty = false;
      });
  },
});

export const {
  updateNotifications,
  updateSelectedNotification,
  setTotalPages,
  setCurrentPage,
  updateFilter,
  handleSearch,
  setSearchTerm,
  updateEmpty,
  updateNotificationsTypes,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
