import { Pagination, Stack } from "@enerbit/base";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { setCurrentPage, setSearchTerm, updateFilter } from "../../store/slices/notificationsSlice";
import { getNotifications } from "../../store/thunks/notifications";

const TablePagination = () => {
  const totalPages = useSelector((state: RootState) => state.notifications.totalPages);
  const currentPage = useSelector((state: RootState) => state.notifications.currentPage);
  const dispatch = useDispatch<AppDispatch>();

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    event.preventDefault();
    dispatch(setCurrentPage(value - 1));
    dispatch(getNotifications());
    dispatch(updateFilter("all"));
    dispatch(setSearchTerm(""));
  };

  return (
    <Stack spacing={2}>
      <Pagination
        page={currentPage + 1}
        count={totalPages}
        shape='rounded'
        onChange={handleChange}
      />
    </Stack>
  );
};

export default TablePagination;
