export interface NotificationsState {
  notifications: NotificationItem[];
  filteredNotifications: NotificationItem[];
  selectedNotification: NotificationItem | undefined;
  loading: boolean;
  empty: boolean;
  totalPages: number;
  currentPage: number;
  selectedFilter: string;
  searchTerm: string;
  error: boolean;
  notificationsTypes: string[];
}

export interface NotificationsModel {
  items: NotificationItem[];
  page: number;
  size: number;
  pages: number;
  total: number;
}

export interface NotificationItem {
  id: string;
  subject: string;
  body: string;
  metadata: string;
  topic?: Topic;
  created_at: string;
}

export interface Topic {
  name: string;
  description: string;
  id: string;
  created_at: string;
}

export enum NotificationsType {
  DEFAULT = "default",
  DIAGNOSTICS = "diagnostics",
  INITIAL_INSPECTIONS = "initial_inspections",
}

export const notificationsTypesNames: Record<NotificationsType, string> = {
  [NotificationsType.DEFAULT]: "Directa",
  [NotificationsType.DIAGNOSTICS]: "Diagnosticos emitidos",
  [NotificationsType.INITIAL_INSPECTIONS]: "Inspecciones iniciales",
};
