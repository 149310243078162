import {
  Box,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@enerbit/base";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import {
  updateNotifications,
  updateSelectedNotification,
} from "../../store/slices/notificationsSlice";
import DetailsModal from "./DetailsModal";
import TablePagination from "./TablePagination";
import { formatDate, sortArray } from "../../helpers";
import { NotificationsType, notificationsTypesNames } from "../../models/nofitications";

const NotificationsTable = () => {
  const [orderDirection, setOrderDirection] = useState<string>("asc");
  const [openModal, setOpenModal] = useState<boolean>(false);

  const rows = useSelector((state: RootState) => state.notifications.filteredNotifications);

  const dispatch = useDispatch<AppDispatch>();

  const handleSortRequest = () => {
    dispatch(updateNotifications(sortArray(rows, orderDirection)));
    setOrderDirection(orderDirection === "asc" ? "desc" : "asc");
  };

  const handleShowDetail = (notification: any) => {
    dispatch(updateSelectedNotification(notification));
    setOpenModal(true);
  };

  return (
    <>
      <div className='table-container'>
        <TableContainer>
          <Table sx={{ borderCollapse: "separate", borderSpacing: "0 10px" }}>
            <TableHead className='table-head'>
              <TableRow>
                <TableCell align='left'>Asunto</TableCell>
                <TableCell align='left' onClick={handleSortRequest}>
                  <TableSortLabel active={true} direction={orderDirection as any}>
                    Fecha y hora
                  </TableSortLabel>
                </TableCell>
                <TableCell align='left'>Tipo</TableCell>
                <TableCell align='left'></TableCell>
              </TableRow>
            </TableHead>
            <TableBody className='table-body'>
              {rows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.subject}</TableCell>
                  <TableCell>{formatDate(row.created_at)}</TableCell>
                  <TableCell>
                    {row.topic
                      ? notificationsTypesNames[row.topic.name as NotificationsType]
                      : notificationsTypesNames[NotificationsType.DEFAULT]}
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={() => handleShowDetail(row)}
                      variant='contained'
                      className='detail-button'>
                      Ver detalle
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {rows.length > 0 && (
          <Box sx={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "15px" }}>
            <TablePagination />
          </Box>
        )}
      </div>
      <DetailsModal
        open={true}
        handleClose={() => {
          setOpenModal(openModal);
          dispatch(updateSelectedNotification(undefined));
        }}
      />
    </>
  );
};

export default NotificationsTable;
